<template>
    <vx-card :title="(this.id ? 'Edit' : 'Create') + ' Inter Storage Document'">
        <div class="vx-row mb-6">
            <vs-button 
                class="ml-4 mt-2" 
                color="danger" 
                icon-pack="feather" 
                icon="icon-arrow-left"
                @click="handleBack()"
            >Back</vs-button>
        </div>
        <div class="flex md:flex-row">
            <div class="w-full pr-6 md:basis-1/2">
                <h4>Selection</h4>
                <hr>
                <br>
                <div class="vx-row mb-6" v-if="readonly && id">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span>Inter Storage Document</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <vs-input :value="code" :readonly="true" class="w-full"></vs-input>
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span>Warehouse</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <Warehouse 
                            :value="this.selectedWarehouse"
                            @selected="(val) => { this.selectedWarehouse = val; onChangeWarehouse(val); }"
                        ></Warehouse>
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span>Type</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <v-select :filterable="true" :options="optionType" v-model="selectedType" track-by="id" label="name" :clearable="false" />
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span>Source Warehouse Area</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <v-select 
                            :filterable="true" 
                            :options="optionSourceWarehouseArea" 
                            :value="selectedSourceWarehouseArea"
                            :searchable="true" 
                            label="name"
                            :clearable="false"
                            @input="(val) => { this.selectedSourceWarehouseArea = val; onChangeSrcWarehouseArea(val); }" />
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span>Destination Warehouse Area</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <v-select 
                            :filterable="true" 
                            :options="optionDestinationStorageArea"
                            v-model="selectedDestinationWarehouseArea" 
                            :searchable="true" 
                            label="name"
                            :clearable="false"
                        />
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span>Notes</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <vs-textarea v-model="notes" width="100%" />
                    </div>
                </div>
                <vs-divider style="margin-left: 0%" v-if="docStatus > 0">
                    Approval Status
                </vs-divider>
                <div class="vx-row mb-6" v-if="docStatus > 0">
                    <table class="m-3 vs-table vs-table--tbody-table table-bordered" width="100%">
                        <thead class="vs-table--thead">
                            <tr>
                                <th>Approver</th>
                                <th>Status</th>
                                <th>Inbox Time</th>
                                <th>Action Time</th>
                                <th>Reason</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(tr, index) in approvalReason" :key="index" style="padding-bottom: 5px">
                                <td class="td vs-table--td" style="padding: 5px">
                                    {{ tr.approver }}
                                </td>
                                <td class="td vs-table--td" style="padding: 5px">
                                    {{ tr.status }}
                                </td>
                                <td class="td vs-table--td" style="padding: 5px">
                                    {{ tr.inbox_time }}
                                </td>
                                <td class="td vs-table--td" style="padding: 5px">
                                    {{ tr.action_time }}
                                </td>
                                <td class="td vs-table--td" style="padding: 5px">
                                    {{ tr.reason }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="w-full md:basis-1/2">
                <div class="w-full pr-6 md:basis-1/2">
                    <h4>Attachments</h4>
                    <hr>
                    <br>
                    <div class="vx-row mb-6">
                        <div class="vx-col sm:w-1/3 w-full flex items-center">
                            <label>Attachment<small style="color: red">(only: jpg, jpeg, pdf, doc, docx, png, xlsx, xls)</small></label>
                        </div>
                        <div class="vx-col sm:w-2/3 w-full">
                            <input id="fileInput" name="file" class="w-full inputx" type="file" ref="file" multiple="multiple"
                                accept=".jpg, .jpeg, .png, .pdf, .doc, .docx, .xlsx, .xls" @change="onChangeAttachment"
                            />
                        </div>
                        <vs-divider style="margin-left: 0%">
                            List Attachment
                        </vs-divider>
                    </div>
                    <div class="vx-row mb-3 mt-6" style="margin-left: 0%">
                        <table class="vs-table vs-table--tbody-table">
                            <template>
                                <tr class="tr-values vs-table--tr tr-table-state-null selected" v-bind:key="tr.NameFile" v-for="(tr, i) in fileAttachment">
                                    <td class="td vs-table--td">{{ tr.NameFile }}</td>
                                    <td>
                                        <img :src="urlFile(tr)" style="width: 100px; height: 100px; object-fit: cover;" class="max-w-sm" @click="openPreview(i)" alt="">
                                    </td>
                                    <td class="td vs-table--td">
                                        <vx-tooltip text="Download" v-if="tr.PathFile != ''">
                                            <vs-button 
                                                type="line" 
                                                icon-pack="feather" 
                                                icon="icon-download" 
                                                :disabled="!tr.PathFile"
                                                @click.stop="download(tr)"
                                            />
                                        </vx-tooltip>
                                    </td>
                                    <td class="td vs-table--td">
                                        <template>
                                            <vx-tooltip text="Delete">
                                                <vs-button 
                                                    type="line" 
                                                    icon-pack="feather" 
                                                    icon="icon-trash"
                                                    @click.stop="handleDeleteAttachment(i)"
                                                />
                                            </vx-tooltip>
                                        </template>
                                    </td>
                                </tr>
                            </template>
                        </table>
                    </div>
                    <vue-easy-lightbox
                        :visible="visible"
                        :imgs="images"
                        :index="index"
                        @hide="handleHide"
                    />
                </div>
            </div>
        </div>
        <br>
        <div class="flex md:flex-row">
            <div class="w-full">
                <h4>Moved Items</h4>
                <hr>
                <div class="vx-row pt-2"></div>
            </div>
        </div>
        <div class="vx-row mb-6">
            <table width="100%" class="m-3 vs-table vs-table--tbody-table">
                <thead class="vs-table--thead">
                    <tr>
                        <th width="33%">SKU Code</th>
                        <th width="20%">Batch / Exp. Date</th>
                        <th width="10%">Available Qty (UOM)</th>
                        <th width="7%">UOM</th>
                        <th width="8%">Moved Qty</th>
                        <th width="10%">Moved Unit</th>
                        <th width="7%">Qty UOM</th>
                        <th width="5%"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(tr, index) in itemLines" :key="index" style="padding-bottom: 5px">
                        <td class="td vs-table--td" style="padding: 5px">
                            <multiselect
                                :allowEmpty="false"
                                :options="tr.optionItem"
                                v-model="tr.selected_items"
                                :selected-label="''"
                                :select-label="''"
                                :deselect-label="''"
                                :label="'label'"
                                :track-by="'label'"
                                :placeholder="''"
                                @input="(val) => { onChangeSku(val, index); }"
                                @search-change="(val) => { handleSearchLines(index, val, 'sku') }"
                            />
                        </td>
                        <td class="td vs-table--td" style="padding: 5px">
                            <multiselect
                                :allowEmpty="false"
                                :options="tr.optionBatch"
                                v-model="tr.batch"
                                :selected-label="''"
                                :select-label="''"
                                :deselect-label="''"
                                :label="'label'"
                                :track-by="'label'"
                                :placeholder="''"
                                @input="(val) => { onChangeBatch(val, index); }"
                                @search-change="(val) => { handleSearchLines(index, val, 'batch') }"
                            />
                        </td>
                        <td class="td vs-table--td" style="padding: 5px">
                            <vs-input class="w-full" type="text" v-model="tr.available_qty" :readonly="true"/>
                        </td>
                        <td class="td vs-table--td" style="padding: 5px">
                            <vs-input class="w-full" type="text" v-model="tr.uom" :readonly="true"/>
                        </td>
                        <td class="td vs-table--td" style="padding: 5px">
                            <vs-input 
                                class="w-full" 
                                type="number" 
                                v-model="tr.qty"
                                @input="(val) => { onChangeQty(val, index); }"
                            ></vs-input>
                        </td>
                        <td class="td vs-table--td" style="padding: 5px">
                            <multiselect
                                :allowEmpty="false"
                                :options="tr.option_item_unit"
                                v-model="tr.selected_item_unit"
                                :selected-label="''"
                                :select-label="''"
                                :deselect-label="''"
                                :placeholder="''"
                                @input="(val) => { onChangeUnit(val, index); }"
                            />
                        </td>
                        <td class="td vs-table--td" style="padding: 5px">
                            <vs-input class="w-full" type="text" v-model="tr.qty_uom" :readonly="true"/>
                        </td>
                        <td class="td vs-table--td" style="text-align: center">
                            <div class="vx-input-group flex">
                                <vs-button 
                                    v-if="itemLines.length > 1" 
                                    @click.stop="removeRow(index)" 
                                    size="small"
                                    color="danger" 
                                    icon-pack="feather" 
                                    icon="icon-trash" 
                                    style="margin-right: 5px"
                                    title="Remove Row"
                                />
                                <vs-button 
                                    v-if="index == itemLines.length - 1" 
                                    @click.stop="addRow(index)"
                                    size="small" 
                                    color="success" 
                                    icon-pack="feather" 
                                    icon="icon-plus"
                                    title="Add Row"
                                />
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <br>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/1 w-full">
                <div style="
                    position: absolute;
                    left: 50%;
                    -ms-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
                ">
                    <vs-button class="mr-3 mb-2" @click="handleSubmit()" v-if="this.id ? this.$store.getters['user/hasPermissions']('edit') : this.$store.getters['user/hasPermissions']('create')">Submit</vs-button>
                    <vs-button class="ml-4 mt-2" type="border" color="danger" v-on:click="handleBack()">Back</vs-button>
                </div>
            </div>
        </div>
    </vx-card>
</template>

<script>
import _ from "lodash";
import Warehouse from "./component/Warehouse.vue";
import Date from "./component/Date.vue";
import Swal from 'sweetalert2'
import vSelect from "vue-select";
import VueEasyLightbox from 'vue-easy-lightbox'

export default {
    props: {},
    components: {
        Warehouse,
        Date,
        vSelect,
        VueEasyLightbox
    },
    data() {
        return {
            id: null,
            readonly: true,
            disabled: true,
            code: null,
            srcID: null,
            destID: null,
            docStatus: 0,
            optionType: [],
            warehouseArea: [],
            optionSourceWarehouseArea: [],
            selectedSourceWarehouseArea: null,
            optionDestinationStorageArea: [],
            selectedDestinationWarehouseArea: null,
            selectedType: null,
            selectedWarehouse: null,
            notes: "",
            isDisabled: false,
            timeOuts: null,
            approvalReason: [
                {
                    approver: null,
                    status: null,
                    inbox_time: null,
                    action_time: null,
                    reason: null,
                }
            ],
            itemLines: [
                {
                    id: 0,
                    optionItem: [],
                    selected_items: null,
                    optionBatch: [],
                    batch: null,
                    real_available_qty: 0,
                    available_qty: null,
                    uom: null,
                    option_item_unit: [],
                    selected_item_unit: null,
                    qty: 0,
                    qty_uom: null,
                }
            ],
            formDraw: 0,

            // attachments section
            file: null,
            fileUrl: '',
            attachment: "",
            fileAttachment: [],
            IDAttachment: [],
            visible: false,
            images: [],
            index: 0,
        }
    },
    computed: {
        urlFile: () => {
            return (tr) => {
                return tr.File instanceof Blob ? URL.createObjectURL(tr.File) : tr.PathFile
            }
        }
    },
    mounted() {
        this.id = this.$route.params.id;
        if (this.id) {
            this.getData();
        }
        this.getType()
        this.$store.dispatch("user/getPermissions", "inter-storage");
    },
    methods: {
        handleSpliceLines() {
            this.itemLines.splice(1)
            this.itemLines.forEach((line) => {
                line.optionItem = []
                line.selected_items = null
                line.optionBatch = []
                line.batch = null
                line.real_available_qty = 0
                line.available_qty = null
                line.uom = null
                line.option_item_unit = []
                line.selected_item_unit = null
                line.qty = 0
                line.qty_uom = null
            });
        },
        handleGetDefaultSkuOption(warehouseID, warehouseAreaID) {
            this.$http.get("/api/wms/v1/inter-storage/items", {
                params: {
                    warehouse_id: warehouseID,
                    warehouse_area_id: warehouseAreaID,
                    search: "",
                },
            })
            .then((resp) => {
                if (resp.code == 200) {
                    if (resp.data.records) {
                        this.itemLines.forEach(val => {
                            val.optionItem = resp.data.records
                        })
                    } else {
                        this.itemLines.forEach(val => {
                            val.optionItem = []
                        })
                    }
                } else {
                    this.itemLines.forEach(val => {
                        val.optionItem = []
                    })
                }
            })
            .catch((error) => {
                this.$vs.loading.close();
                console.log(error);
            });
        },
        handleGetBatchOption(index, sku_code, warehouse_id, warehouse_area_id) {
            this.$http.get("/api/wms/v1/inter-storage/get-batch", {
                params: {
                    search: "",
                    sku_code: sku_code,
                    warehouse_id: warehouse_id,
                    warehouse_area_id: warehouse_area_id,
                },
            })
            .then(resp => {
                if (resp.code == 200) {
                    if (resp.data.option_batch) {
                        this.itemLines[index].optionBatch = resp.data.option_batch
                    } else {
                        this.itemLines[index].optionBatch = []
                    }
                } else {
                    this.itemLines[index].optionBatch = []
                }
            })
            .catch(error => {
                console.log(error)
            });
        },
        handleGetAvailableQtyUnitOption(index, sku_code, warehouse_id, warehouse_area_id, batch) {
            this.$http.get("/api/wms/v1/inter-storage/get-available-qty-unit", {
                params: {
                    sku_code: sku_code,
                    warehouse_id: warehouse_id,
                    warehouse_area_id: warehouse_area_id,
                    batch: batch,
                },
            })
            .then(resp => {
                if (resp.code == 200) {
                    this.itemLines[index].real_available_qty = resp.data.available_qty
                    this.itemLines[index].available_qty = this.handleUpdateFormat(resp.data.available_qty)
                    this.itemLines[index].uom = resp.data.uom
                    this.itemLines[index].option_item_unit = resp.data.option_units
                } else {
                    this.itemLines[index].real_available_qty = 0
                    this.itemLines[index].available_qty = 0
                    this.itemLines[index].uom = null
                    this.itemLines[index].option_item_unit = []
                }
            })
            .catch(error => {
                console.log(error)
            });
        },
        handleGetQtyUom(index, sku_code, unit) {
            this.$http.get("/api/wms/v1/inter-storage/get-qty-uom", {
                params: {
                    sku_code: sku_code,
                    unit: unit,
                }
            })
            .then(resp => {
                if (resp.code == 200) {
                    this.itemLines[index].qty_uom = this.handleUpdateFormat(resp.data.amount_uom)
                } else {
                    this.itemLines[index].qty_uom = 0
                }
            })
            .catch(error => {
                console.log(error)
            });
        },
        handleUpdateFormat(value) {
            let cleanValue = value.toString().replace(/\D/g, '');
            return new Intl.NumberFormat('id-ID').format(cleanValue);
        },
        handleSearchLines(index, val, type) {
            if (this.timeOuts) {
                clearTimeout(this.timeOuts)
            }

            if (type == "sku") {
                this.timeOuts = setTimeout(this.newSearchSku(val, index), 400)
            } else if (type == "batch") {
                this.timeOuts = setTimeout(this.newSearchBatch(val, index), 400)
            }
        },
        getData() {
            this.$vs.loading();
            this.$http.get("api/wms/v1/inter-storage/edit/" + this.id)
            .then((resp) => {
                if (resp.code == 200) {
                    this.getStorageArea(resp.data.warehouse_id, true)
                    this.code = resp.data.code
                    this.docStatus = resp.data.status
                    this.notes = resp.data.note
                    this.selectedWarehouse = resp.data.warehouse
                    this.selectedType = this.optionType.find((val) => {
                        return val.id == resp.data.type_id;
                    })
                    if (this.optionSourceWarehouseArea.length > 0) {
                        this.selectedSourceWarehouseArea =
                            this.optionSourceWarehouseArea.find((val) => {
                                return val.id == resp.data.from_warehouse_area_id;
                            });
                    }
                    this.srcID = resp.data.from_warehouse_area_id
                    if (this.optionDestinationStorageArea.length > 0) {
                        this.selectedDestinationWarehouseArea =
                            this.optionDestinationStorageArea.find((val) => {
                                return val.id == resp.data.to_warehouse_area_id;
                            });
                    }
                    this.destID = resp.data.to_warehouse_area_id
                    this.approvalReason = resp.data.approver_reason
                    if (resp.data.attachment) {
                        resp.data.attachment.forEach((val) => {
                            this.fileAttachment.push({
                                id: val.id,
                                NameFile: val.file_name,
                                PathFile: val.file_url,
                            });
                            this.images.push(val.file_url);
                        });
                    }
                    this.itemLines = resp.data.item_sku
                    this.itemLines.forEach(val => {
                        val.available_qty = this.handleUpdateFormat(val.real_available_qty)
                        val.qty_uom = this.handleUpdateFormat(val.qty_uom)
                    })
                    this.handleGetDefaultSkuOption(this.selectedWarehouse.id, resp.data.from_warehouse_area_id)
                    this.$vs.loading.close();
                } else {
                    this.$vs.loading.close();
                }
            })
            .catch((error) => {
                this.$vs.loading.close();
                console.log(error);
            });
        },
        handleBack() {
            this.$router.push({
                name: "inter-storage",
                params: { status: this.docStatus }
            });
        },
        getType() {
            this.$http.get("/api/wms/v1/master/stock-relocation-type")
            .then((resp) => {
                if (resp.code == 200) {
                    if (resp.data) {
                        this.optionType = resp.data
                    }
                    // this.$vs.loading.close();
                } else {
                    // this.$vs.loading.close();
                }
            })
            .catch((error) => {
                this.$vs.loading.close();
                console.log(error);
            });
        },
        addRow(index) {
            if (!this.itemLines[index].selected_items) {
                this.$vs.notify({
                    title: "Error",
                    text: "Please select Item Line first",
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-check",
                });
                return true;
            } else {
                this.itemLines.push({
                    optionItem: [],
                    selected_items: null,
                    optionBatch: [],
                    batch: null,
                    real_available_qty: 0,
                    available_qty: null,
                    uom: null,
                    option_item_unit: [],
                    selected_item_unit: null,
                    qty: 0,
                    qty_uom: null,
                });

                this.handleGetDefaultSkuOption(this.selectedWarehouse.id, this.selectedSourceWarehouseArea.id)
            }
        },
        removeRow(index) {
            this.itemLines.splice(index, 1);
        },
        getStorageArea(whId, init = false) {
            this.$vs.loading();
            this.$http.get("/api/wms/v1/inter-storage/warehouse-area", {
                params: {
                    warehouse_id: whId,
                },
            })
            .then((resp) => {
                if (resp.code == 200) {
                    if (resp.data) {
                        this.warehouseArea = resp.data;
                        if (init) {
                            if (this.selectedType.src_type == "Quarantine") {
                                this.optionSourceWarehouseArea = this.warehouseArea.filter(wa => wa.area_type.Type == "Special" && wa.classification.name == "Holding")
                            } else if (this.selectedType.src_type == "Special") {
                                this.optionSourceWarehouseArea = this.warehouseArea.filter(wa => wa.area_type.Type == "Special" && wa.classification.name != "Holding")
                            } else {
                                this.optionSourceWarehouseArea = this.warehouseArea.filter(wa => wa.area_type.Type == this.selectedType.src_type)
                            }

                            this.selectedSourceWarehouseArea = this.optionSourceWarehouseArea.find((el) => {
                                return el.id == this.srcID;
                            });

                            if (this.selectedType.dest_type == "Quarantine") {
                                this.optionDestinationStorageArea = this.warehouseArea.filter(wa => wa.area_type.Type == "Special" && wa.classification.name == "Holding")
                            } else if (this.selectedType.dest_type == "Special") {
                                this.optionDestinationStorageArea = this.warehouseArea.filter(wa => wa.area_type.Type == "Special" && wa.classification.name != "Holding")
                            } else if (this.selectedType.dest_type == "Others") {
                                this.optionDestinationStorageArea = this.warehouseArea.filter(wa => wa.area_type.Type != "Loading")
                            } else {
                                this.optionDestinationStorageArea = this.warehouseArea.filter(wa => wa.area_type.Type == this.selectedType.dest_type)
                            }

                            this.selectedDestinationWarehouseArea = this.optionDestinationStorageArea.find((el) => {
                                return el.id == this.destID;
                            });
                        }
                    } else {
                        this.optionSourceWarehouseArea = [];
                        this.selectedSourceWarehouseArea = null;
                    }
                    this.$vs.loading.close();
                } else {
                    this.$vs.loading.close();
                }
            })
            .catch((error) => {
                this.$vs.loading.close();
                console.log(error);
            });
        },
        handleSubmit() {
            if (!this.selectedWarehouse) {
                this.$vs.notify({
                    color: "danger",
                    title: "Error",
                    text: "Please select Warehouse",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
                return false;
            }

            if (!this.selectedType) {
                this.$vs.notify({
                    color: "danger",
                    title: "Error",
                    text: "Please select Type",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
                return false;
            }

            if (!this.selectedSourceWarehouseArea) {
                this.$vs.notify({
                    color: "danger",
                    title: "Error",
                    text: "Please select Source Storage Area",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
                return false;
            }

            if (!this.selectedDestinationWarehouseArea) {
                this.$vs.notify({
                    color: "danger",
                    title: "Error",
                    text: "Please select Destination Storage Area",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
                return false;
            }

            if (this.selectedSourceWarehouseArea.id == this.selectedDestinationWarehouseArea.id) {
                this.$vs.notify({
                    color: "danger",
                    title: "Error",
                    text: "Please select different Destination Storage Area",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
                return false;
            }

            if (!this.fileAttachment.length) {
                this.$vs.notify({
                    color: "danger",
                    title: "Error",
                    text: "Please add attachment",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
                return false;
            }

            let skus = []
            let message = ""
            this.itemLines.forEach((val) => {
                if (val.selected_items == null) {
                    message = "please check sku field, sku is required"
                    return false
                }

                if (val.batch == null) {
                    message = "please check batch field, batch is required"
                    return false
                }

                if (val.qty < 1) {
                    message = "item's quantity minimum is 1"
                    return false
                }

                if (val.selected_item_unit == null) {
                    message = "please check unit field, unit is required"
                    return false
                }

                skus.push({
                    id: (val.id) ? val.id : 0,
                    sku_code: val.selected_items.sku_code,
                    sku_name: val.selected_items.item_name,
                    batch: val.batch.batch_external,
                    qty: Number(val.qty),
                    unit_name: val.selected_item_unit,
                })
            });

            if (message != "") {
                this.$vs.notify({
                    color: "danger",
                    title: "Error",
                    text: message,
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
                return false;
            }

            Swal.fire({
                title: 'Submit form',
                text: "Are you sure want to submit document?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Submit'
            }).then((result) => {
                if (result.isConfirmed) {
                    const formData = new FormData();
                    formData.append("type", this.selectedType.name);
                    formData.append("type_id", this.selectedType.id);
                    formData.append("warehouse_id", this.selectedWarehouse.id);
                    formData.append("from_warehouse_area_id", this.selectedSourceWarehouseArea.id);
                    formData.append("to_warehouse_area_id", this.selectedDestinationWarehouseArea.id);
                    formData.append("note", this.notes);
                    formData.append("skus", JSON.stringify(skus));
                    formData.append("status", "0");
                    if (this.fileAttachment.length > 0) {
                        for (let index = 0; index < this.fileAttachment.length; index++) {
                            formData.append("attachments[]", this.fileAttachment[index].File ? this.fileAttachment[index].File : this.fileAttachment[index].id);
                        }
                    }

                    this.$vs.loading();
                    this.$http.post("api/wms/v1/inter-storage/create-or-update/" + (this.id ? this.id : 0), formData)
                    .then(resp => {
                        if (resp.code == 200) {
                            this.$vs.loading.close();
                            Swal.fire({
                                title: 'Done!',
                                text: 'Operation Document has been ' + (this.id ? 'Updated' : 'Created'),
                                icon: 'success',
                            })
                            this.handleBack();
                            this.$vs.loading.close();
                        } else {
                            this.$vs.loading.close();
                            Swal.fire({
                                title: 'Something went wrong',
                                text: resp.message,
                                icon: 'info',
                            });
                        }
                    })
                    .catch((error) => {
                        this.$vs.loading.close();
                        console.log(error);
                        Swal.fire({
                            title: 'Something went wrong',
                            text: error,
                            icon: 'info',
                        });
                    });
                }
            });
        },
        newSearchSku(search, index) {
            this.$http.get("/api/wms/v1/inter-storage/items", {
                params: {
                    warehouse_id: this.selectedWarehouse.id,
                    warehouse_area_id: this.selectedSourceWarehouseArea.id,
                    search: search,
                }
            })
            .then((resp) => {
                if (resp.code == 200) {
                    if (resp.data.records) {
                        this.itemLines[index].optionItem = resp.data.records
                    } else {
                        this.itemLines[index].optionItem = [];
                    }
                } else {
                    this.itemLines[index].optionItem = [];
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        newSearchBatch(search, index) {
            let skuCode = this.itemLines[index].selected_items.sku_code
            this.$http.get("/api/wms/v1/inter-storage/get-batch", {
                params: {
                    search: search,
                    sku_code: skuCode,
                    warehouse_id: this.selectedWarehouse.id,
                    warehouse_area_id: this.selectedSourceWarehouseArea.id,
                }
            })
            .then(resp => {
                if (resp.code == 200) {
                    if (resp.data.option_batch) {
                        this.itemLines[index].optionBatch = resp.data.option_batch
                    } else {
                        this.itemLines[index].optionBatch = []
                    }
                } else {
                    this.itemLines[index].optionBatch = []
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        onChangeSku(val, index) {
            if (val) {
                this.itemLines[index].optionBatch = []
                this.itemLines[index].batch = null
                this.itemLines[index].uom = null
                this.itemLines[index].available_qty = 0
                this.itemLines[index].real_available_qty = 0
                this.itemLines[index].option_item_unit = []
                this.itemLines[index].selected_item_unit = null
                this.itemLines[index].qty_uom = null

                this.handleGetDefaultSkuOption(this.selectedWarehouse.id, this.selectedSourceWarehouseArea.id)
                this.handleGetBatchOption(index, val.sku_code, this.selectedWarehouse.id, this.selectedSourceWarehouseArea.id)
            }
        },
        onChangeBatch(val, index) {
            this.itemLines[index].real_available_qty = 0
            this.itemLines[index].available_qty = null
            this.itemLines[index].uom = null
            this.itemLines[index].option_item_unit = []
            this.itemLines[index].selected_item_unit = null

            this.itemLines[index].batch = val
            this.handleGetAvailableQtyUnitOption(index, this.itemLines[index].selected_items.sku_code, this.selectedWarehouse.id, this.selectedSourceWarehouseArea.id, val.batch_external)
        },
        onChangeQty(val, index) {
            this.itemLines[index].qty = val
            if (this.itemLines[index].qty < 0) {
                this.$vs.notify({
                    color: "danger",
                    title: "Error",
                    text: "Qty must be greater than zero",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
                this.itemLines[index].qty = 0
            }
        },
        onChangeUnit(val, index) {
            this.itemLines[index].qty_uom = null
            this.handleGetQtyUom(index, this.itemLines[index].selected_items.sku_code, val)
        },
        onChangeWarehouse(val) {
            if (this.formDraw >= 2) {
                this.handleSpliceLines()

                this.selectedType = null
                this.warehouseArea = null
                this.optionSourceWarehouseArea = [];
                this.selectedSourceWarehouseArea = null;
                this.optionDestinationStorageArea = [];
                this.selectedDestinationWarehouseArea = null;
            }
            this.formDraw++ // 1
            this.getStorageArea(val.id, false)
        },
        onChangeSrcWarehouseArea(val) {
            if (val) {
                this.handleSpliceLines()
                this.handleGetDefaultSkuOption(this.selectedWarehouse.id, this.selectedSourceWarehouseArea.id)
            }
        },

        // attachments section
        download(tr) {
            const link = document.createElement('a');
            link.href = this.urlFile(tr);
            link.download = tr.NameFile;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        },
        onChangeAttachment() {
            if (this.$refs.file.files.length > 0) {
                this.file = this.$refs.file.files;
                for (let i = 0; i < this.$refs.file.files.length; i++) {
                    const fileIdx = this.$refs.file.files[i]
                    this.fileAttachment.push({
                        NameFile: this.file[i].name,
                        File: this.file[i],
                    });
                    this.images.push(URL.createObjectURL(fileIdx));
                }
                document.querySelector("#fileInput").value = "";
            }
        },
        openPreview(index) {
            this.index = index;
            this.visible = true;
        },
        handleHide() {
            this.visible = false;
        },
        handleDeleteAttachment(index) {
            this.fileAttachment.splice(index, 1);
            this.images.splice(index, 1);
        },
    },
    watch: {
        selectedWarehouse(val) {
            this.getStorageArea(val.id)
        },
        selectedType(val, old) {
            if (val) {
                if (this.formDraw >= 2) {
                    this.handleSpliceLines()
                }
                this.formDraw++ // 2

                if (!old || val.src_type != old.src_type) {
                    if (val.src_type == "Quarantine") {
                        this.optionSourceWarehouseArea = this.warehouseArea.filter(wa => wa.area_type.Type == "Special" && wa.classification.name == "Holding")
                    } else if (val.src_type == "Special") {
                        this.optionSourceWarehouseArea = this.warehouseArea.filter(wa => wa.area_type.Type == "Special" && wa.classification.name != "Holding")
                    } else {
                        this.optionSourceWarehouseArea = this.warehouseArea.filter(wa => wa.area_type.Type == val.src_type)
                    }
                    this.selectedSourceWarehouseArea = null
                }
                if (!old || val.dest_type != old.dest_type) {
                    if (val.dest_type == "Quarantine") {
                        this.optionDestinationStorageArea = this.warehouseArea.filter(wa => wa.area_type.Type == "Special" && wa.classification.name == "Holding")
                    } else if (val.dest_type == "Special") {
                        this.optionDestinationStorageArea = this.warehouseArea.filter(wa => wa.area_type.Type == "Special" && wa.classification.name != "Holding")
                    } else if (val.dest_type == "Others") {
                        this.optionDestinationStorageArea = this.warehouseArea.filter(wa => wa.area_type.Type != "Loading")
                    } else {
                        this.optionDestinationStorageArea = this.warehouseArea.filter(wa => wa.area_type.Type == val.dest_type)
                    }
                    this.selectedDestinationWarehouseArea = null
                }
            }
        },
    }
}
</script>

<style>
.multiselect--above .multiselect__content-wrapper{
    bottom: auto !important;
    top: 100% !important;
}
</style>